import React from 'react'
import Clock from "../../assets/svg/pedidos/clock.svg";
import Eye from "../../assets/svg/pedidos/eye.svg";
import Moment from 'react-moment';
import { getStatusSeparacao } from "../../helpers/functionsHelps"

export default function Row({ details, openDetails, options, openOptions, item, openEtiqueta }) {
    return (
        <>
            <tr className="bg-white border-b-2 border-gray-200">
                {/*<td className="py-3 px-6">
                    <input type="checkbox" />
                </td>*/}
                <td className="py-3 px-6">
                    {item.numeroPedido}
                </td>
                <td className="py-3 px-6">
                    {item.numeroNF ?? (<span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                                            Pendente 
                                        </span>)}
                </td>
                <td className="py-3 px-6">
                <Moment format="DD/MM/YYYY HH:mm">
                    {item.dataPedido}
                </Moment>
                </td>
                <td className="py-3 px-6 uppercase">
                    {item.tipoIntegracao}
                </td>
                <td className="flex items-center py-4 px-6">
                    {getStatusSeparacao(item.statusSeparacao)}
                </td>
                <td className="py-3 px-6">
                    {(item.statusSeparacao != 99 && item.statusSeparacao != 98 && item.statusSeparacao != 9 && item.statusSeparacao != 10 && item.statusSeparacao != 12) && (
                        <span className="font-bold text-2xl cursor-pointer" onClick={() => openEtiqueta(item)}>
                            ...
                        </span>
                    )}

                    {options && (
                        <div className="absolute flex flex-col right-2 bg-white drop-shadow-xl w-[200px] px-3 py-3 font-semibold rounded-md origin-top">
                            <span className="hover:bg-gray-300 transition-all duration-300 p-1 rounded-md cursor-pointer" onClick={() => openEtiqueta()}>Enviar etiqueta</span>
                        </div>
                    )}
                </td>

                {/*

                <td className="py-3 px-6">
                    <img src={Eye} alt="Eye Icon" className="cursor-pointer" onClick={() => openDetails()}/>
                </td>

                <td className="py-3 px-6">
                    <span className="font-bold text-2xl cursor-pointer" onClick={() => openOptions()}>
                        ...
                    </span>

                    {options && (
                        <div className="absolute flex flex-col right-2 bg-white drop-shadow-xl w-[200px] px-3 py-3 font-semibold rounded-md origin-top">
                            <span className="hover:bg-gray-300 transition-all duration-300 p-1 rounded-md cursor-pointer">Cancelar pedido</span>
                            <span className="hover:bg-gray-300 transition-all duration-300 p-1 rounded-md cursor-pointer">Pausar processo</span>
                            <span className="hover:bg-gray-300 transition-all duration-300 p-1 rounded-md cursor-pointer">Enviar etiqueta</span>
                            <span className="hover:bg-gray-300 transition-all duration-300 p-1 rounded-md cursor-pointer">Resolver erro</span>
                        </div>
                    )}
                </td>
                    */}
            </tr>
        </>
    )
}
